import {Roles} from "../Configs/DatabaseConsts";

export const CATEGORY_SELECT = 'CATEGORY_SELECT'
export const USERS_SELECT = 'USERS_SELECT'
export const COACH_SELECT = 'COACH_SELECT'
export const STAFF_SELECT = 'STAFF_SELECT'
export const MESSAGE_TEMPLATE_SELECT = 'MESSAGE_TEMPLATE_SELECT'
export const TASK_TYPES_SELECT = 'TASK_TYPES_SELECT'
export const CUSTOM_FIELD_SELECT = 'CUSTOM_FIELD_SELECT'

export const LEAD_SOURCE_SELECT = 'LEAD_SOURCE_SELECT'
export const LEAD_STATUS_SELECT = 'LEAD_STATUS_SELECT'
export const SPACE_SELECT = 'SPACE_SELECT'
export const LOST_REASONS_SELECT = 'LOST_REASONS_SELECT'

export const ONE_TIME_PURCHASE = 'ONE_TIME_PURCHASE'
export const EXISTING_MEMBERSHIP = 'EXISTING_MEMBERSHIP'
export const NO_MEMBERSHIP = 'NO_MEMBERSHIP'

export const ENTER_DEBT = 'ENTER_DEBT'
export const FREE_PURCHASE = 'FREE_PURCHASE'

export const PAYMENT_OPTION = 'PAYMENT_OPTION'
export const PAYMENT_METHOD = 'PAYMENT_METHOD'
export const CVV_AND_INSTALLMENTS = 'CVV_AND_INSTALLMENTS'
export const BILLING_ADDRESS = 'BILLING_ADDRESS'
export const TOKEN_IFRAME = 'TOKEN_IFRAME'

export const SCHEDULE_TYPE = 'schedule'
export const AVAILABILITY_TYPE = 'availability'
export const SPACES_TYPE = 'spaces'

export const HORIZONTAL = 'horizontal'
export const VERTICAL = 'vertical'

export const SINGLE_SESSION_EDIT_TYPE = 'SINGLE_SESSION_EDIT_TYPE'
export const SERIES_FUTURE_SESSIONS_EDIT_TYPE = 'SERIES_FUTURE_SESSIONS_EDIT_TYPE'

export const LEAD_TYPE = 'lead'
export const USER_TYPE = 'user'
export const AGGREGATOR_TYPE = 'aggregator'
export const STAFF_TYPE = 'coach'

export const CLASS = 'class'
export const APPOINTMENT = 'appointment'
export const WORKSHOP = 'workshop'
export const BLOCKED = 'blocked'
export const HUGIM = 'hugim'

export const TOAST_TYPE = 'toast'
export const PAGE_TYPE = 'page'
export const MESSAGE_TYPE = 'message'
export const NO_MESSAGE_TYPE = 'no_message'

export const ADD_TO_EVENT = 'add_to_event'

export const messageAudience = [
    'PrivateMessage',
    'ReportMessage',
    'NonActiveMembersMessage',
    'ActiveMembersMessage',
    'OpenLeadsMessage',
    'LostLeadsMessage',
    'StaffMembersMessage',
];

export const SMS = 'sms';
export const PUSH = 'push';
export const EMAIL = 'email';
export const WHATSAPP = 'whatsapp';

export const fieldUpdateFunctionNames = {
    EVENT_SPACE: 'updateEventSpace',
    EVENT_STAFF: 'updateEventStaff',
    BOX_CATEGORY: 'updateBoxCategory',
    EVENT_TIME: 'updateEventTime',
}

export const UNKNOWN_CHIP = 'unknown-chip';
export const IDENTIFIER_ERROR = 'IDENTIFIER_ERROR';

export const searchUserTypes = ['user', 'lead', 'union', 'freefit', 'coach', 'aggregator'];

export const IMMEDIATE = 'IMMEDIATE'
export const HOK = 'HOK'
export const TRIAL = 'TRIAL'

export const seriesPaymentDBTranslate = {
    recurring: HOK,
    regular: IMMEDIATE,
    trial: TRIAL
}

export const customFieldTypes = {
    TEXT: 'text',
    SELECT: 'select',
    BOOLEAN: 'boolean',
    NUMBER: 'number',
    DATE: 'date',
    PHONE: 'phone'
}