import {Dimensions, Platform} from "react-native";
import { store } from "../redux/store";
import { setAngularState } from "../redux/rootManagerSlice";
import { openAngularPopup } from "./IframeIntegrationServices";
import { loginWithToken } from "../redux/authSlice";
import { fetchApi } from "./HTTP";
import {BoxTypes, MembershipTypes, Roles} from "../Configs/DatabaseConsts";
import moment from "moment";
import {Colors} from "../styles/Colors";
import {handleAvailabilityEventLogic} from "./ScheduleService";
import { t } from "./i18n";
import {AGGREGATOR_TYPE, EMAIL, LEAD_TYPE, PUSH, SMS, STAFF_TYPE, WHATSAPP} from "./ConstHelper";
import i18next from "i18next";

const checkIsMobile = () => {
    const screenSize = Dimensions.get('screen').width;
    const MOBILE_MAX_SCREEN_SIZE = 600;
    return screenSize <= MOBILE_MAX_SCREEN_SIZE;
};

const checkIsTablet = () => {
    const screenSize = Dimensions.get('screen').width;
    const MOBILE_MAX_SCREEN_SIZE = 600;
    const TABLET_MAX_SCREEN_SIZE = 1100;
    return screenSize >= MOBILE_MAX_SCREEN_SIZE && screenSize <= TABLET_MAX_SCREEN_SIZE;
};

export const getKeyByValue = (object, value) => Object.keys(object).find(key => object[key] === value);

export const isMobile = checkIsMobile();
export const isTablet = checkIsTablet();

export const locationCheck = () => fetch("https://api.ipstack.com/check?access_key=fa539341cace9c3eba25e238ccbae6f7").then(res => res.json()).catch(err => err);

export const generateUpgradeLink = (box, user, isFitnessType) => {
    const fitnessConcat = isFitnessType ? '-fitness' : '';
    const userPackage = box?.packages && box.packages.name.toLowerCase();
    const isIL = box?.locations_box[0].country_code === 'IL';
    let packageUrl = '';
    let maxLink = '';
    if (userPackage) {
        packageUrl += isIL ? 'pricing-' : 'product-';
        if (userPackage.includes('basic')) {
            packageUrl += (isIL && !isFitnessType ? 'free-basic' : 'basic-plan') + fitnessConcat;
        }
        else if (userPackage.includes('standard')) {
            packageUrl += "standard-plan" + fitnessConcat;
        }
        else if (userPackage.includes('trial mode max')) {
            maxLink = 'https://www.arbox.co.il/platform/pricing-free-plan-max';
        } else if (userPackage.includes('trial mode tailor-brands') || userPackage.includes('free account - tailor')) {
            maxLink = 'http://www.arboxapp.com/platform/product---basic-plan---taylor';
        } else packageUrl += 'free-plan' + fitnessConcat;
    } else {
        packageUrl = 'free-plan' + fitnessConcat;
    }
    if (box.id && user.user_fk) {
        const queryString = '?custom1=' + box.id +
            '&custom2=' + user.user_fk +
            '&customer_last_name=' + box.name +
            '&company_name=' + box.name +
            '&email_address=' + user.email +
            '&phone_number=' + box.phone +
            '&address=' + box.address +
            '&city=' + box.city +
            '&state=' + box.state +
            '&country=' + box.country;
        const url = maxLink || ((isIL ? 'https://www.arbox.co.il/platform/' : 'https://www.arboxapp.com/platform/') + packageUrl);
        return url + queryString
    }
    return null;
}

export const getThankYouPageLink = async (box) => {
    const result = await fetchApi('getPackages', 'GET', null, false, true);

    const baseLink = "https://www.arboxapp.com/platform/purchase-thank-you-";
    const userPackage = result.box.packages.name.toLowerCase();
     console.log("userPackage " , userPackage);

    // if (!userPackage || userPackage.includes('free') || userPackage.includes('trial'))
    //     console.log("free/trial pack");
    //     return baseLink;
    if (userPackage.includes('basic'))
        return baseLink + 'basic';
    if (userPackage.includes('standard'))
        return baseLink + 'standard';
    if (userPackage.includes('pro'))
        return baseLink + 'pro';
    return baseLink + 'basic';
}

export const handleTimeCardPopup = (dispatch) => {
    dispatch(setAngularState({
        modalSrc: openAngularPopup('popupHandler?type=timeCard')
    }));
}

export const pushToDataLayer = (eventName) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': eventName
    });
}

export const sendDataEvent = (eventName, properties) => {
    try {
        if (store.getState().auth?.data?.originalUser) {
            return;
        };
        window.mixpanel?.track?.(eventName, properties);
    } catch (error) {
        console.log('mixpanel track event error');
    }
}

export const freshpaintIdentify = (box, user) => {
    window.mixpanel?.identify?.(user.email);
    window.mixpanel?.people?.set?.({
        "user_fk": user.user_fk,
        "$first_name": user.first_name,
        "$last_name": user.last_name,
        "$phone": user.phone,
        "boxId": box.id,
        "role_fk": user.roles,
        "$country_code": box.locations_box[0].country_code,
        "package_name": box.packages?.name,
        "box_type": box.box_type.type,
        "box_name": box.name,
        "$email": user.email,
        "$name": `${user.first_name} ${user.last_name || ''}`,
    });
}

export const freshpaintReset = () => {
    window.mixpanel?.reset?.();
}

export const intercomIdentify = (box, user) => {
    console.log(" in intercomIdentify")
   //  if (window.intercomSettings) {
        window.intercomSettings = {
            ...window.intercomSettings,
            boxId: box.id,
            clubName: box.name,
            box_type: box.box_type?.type,
            package_name: box.packages?.name,
            package_id: box.packages_id,
            White_label_link: box.white_label_property,
            payment_service: box.box_payment_services?.payment_service,
            company: {
                id: box.id,
                name: box.name,
            },
            app_id: "jw8p0yvl",
            email: user.email,
            phone: user.phone,
            user_fk: user.user_fk,
            user_id: user.email,
            user_hash: user.chat_token,
            schedule_link: generateSiteLink('schedule', {referer: 'site'}),
            country_code: box.locations_box[0].country_code,
        };
        window.Intercom?.('boot', {...window.intercomSettings})
    console.log("window.intercomSettings " , window.intercomSettings)
  //   }
}


export const smartlookIdentify = (box, user) => {
    window.smartlook?.('identify', user.email, {
        box_id: box.id,
        box_name: box.name,
        email: user.email,
        user_fk: user.user_fk,
        first_name : user.first_name,
        last_name : user.last_name,
        ub_created: user.created_at
    });
}

export const fullviewIdentify = (box, user) => {
    window.$fvIdentity = {
        id: user.user_fk,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        disableReplaysForUser: false,
        Box_fk: box.id,
        Phone: user.phone
    };
}



export const intercomLanguageOnChange = (lang) => {
    if(window.intercom){
        const alignment = lang === 'he' ? 'left' : 'right';
        const language_override = lang === 'he' ? 'he' : 'en';
        window.Intercom('boot', {
            ...window.intercomSettings,
            alignment,
            language_override
        });
    }
}

export const checkIfAngularLoggedIn = async (auth, dispatch) => {
    if (!auth) {
        const user = localStorage.getItem('ls.user') ? JSON.parse(localStorage.getItem('ls.user')) : null;
        if (user && user.token) {
            dispatch(loginWithToken({ token: user.token, isOldServerToken: true }));
        }
    }
}

// service worker - notifications

const askPermission = () => {
    // Legacy support Notification.requestPermission can either return a promise, or accept a callback(deprecated)
    // Side note: most likley callback impls of Notification.requestPermission might not have native promises
    return new Promise(function (resolve, reject) {
        const returnedPromise = Notification.requestPermission(function (result) {
            resolve(result);
        });
        if (returnedPromise)
            returnedPromise.then(resolve, reject);
    }).then(function (result) {
        if (result !== 'granted') {
            throw new Error('no permission');
        }
    });
}

const saveSubscription = (pushSubscription) => {
    return fetchApi('webPush/subscribe', 'POST', pushSubscription, false, true);
}

const urlBase64ToUint8Array = (base64String) => {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

const subscribeUser = (registration) => {
    return askPermission().then(function () {
        const subscribeOptions = {
            userVisibleOnly: true, // MUST true always
            applicationServerKey: urlBase64ToUint8Array('BKXADcOjGugnOKvSKK4DKluGn9QJ-IBcEA_N6kWtY3awYBx4P7MAOW0UE6jWhgRUzsDxnrHt6SVbxED8pVZsWTI')
        };
        return registration.pushManager
            .subscribe(subscribeOptions)
            .then(saveSubscription);
    })
}

export const serviceWorkerRegister = () => {
    if ("serviceWorker" in navigator && "Notification" in window) {
        console.log('in navigation', navigator.serviceWorker);
        navigator.serviceWorker.register('./sw.js').then((reg) => {
            if (Notification.permission === "granted") {
                /* do our magic */
                reg.pushManager.getSubscription().then(function (sub) {
                    //TODO ADD USER UI?
                    if (sub === null) {
                        // Update UI to ask user to register for Push
                        subscribeUser(reg);
                    } else {
                        // We have a subscription, update the database
                        //TODO We handle updates on pushsubscriptionchange event || Consider removing this func call
                        subscribeUser(reg);
                    }
                });
            } else if (Notification.permission === "blocked") {
                /* the user has previously denied push. Can't reprompt. */
            } else if (
                Notification.permission !== "denied" ||
                Notification.permission === "default"
            ) {
                /* show a prompt to the user */
                Notification.requestPermission(function (permission) {
                    // If the user accepts, let's create a notification
                    if (permission === "granted") {
                        subscribeUser(reg);
                        var notification = new Notification(
                            "תתחדשו! מעתה תהנו מתזכורות לפני מועד המשימה"
                        );
                    }
                });
            }
        })
    }
}

export const generateSiteLink = (path, params, sendEvent) =>  {
    if (sendEvent) {
        sendDataEvent('Open or copy site link')
    }
    const box = store.getState().box.box
    var identifier = box.external_url_id;
    var lang = box?.locations_box[0].country_code === 'IL' ? 'he' : 'en';
    if(box.isBoxHasNewSite && params.scheduleType !== 'workshops') return translateToSiteV2(path, params)
    const queries = params.scheduleType && `&scheduleType=${params.scheduleType}`
    return `${process.env.REACT_APP_ARBOX_SITE_URL}${path}?identifier=${identifier}&whitelabel=${box.white_label_property?.app_names?.name || 'Arbox'}&referer=${params.referer}&lang=${lang}${queries ?? ''}`;
}

const translateToSiteV2 = (path, params) => {
    let route = null
    if(path === 'schedule') {
        if(!params.scheduleType || params.scheduleType === 'schedule') {
            route = 'group'
        } else if(params.scheduleType === 'appointment') {
            if(params.availabilityType === 'SPACE_AVAILABILITY_TYPE') {
                route = 'space-appointment'
            } else {
                route = 'staff-appointment'
            }
        }
    } else if(path === 'shop' || path === 'cart') {
        route = 'membership'
        if(path === 'shop/item/') {
            const itemId = path.split('/').pop()
            route = `/membership/${itemId}`
        }
    }
    return generateSiteV2Link(route, params)
}

export const generateSiteV2Link = (route, params = {}) => {
    const box = store.getState().box.box
    const identifier = box.external_url_id;
    const lang = store.getState().userSettings.lang.language === 'English' ? 'en' : 'he'
    let searchParams = `whitelabel=${box.white_label_property?.app_names?.name || 'Arbox'}`
    if(lang) searchParams += `&lang=${lang}`
    if(params.location) searchParams += `&location=${params.location}`
    if(params.referer) searchParams += `&referrer=${params.referer}`
    return `${process.env.REACT_APP_ARBOX_NEW_SITE_PREFIX}${identifier}.${process.env.REACT_APP_ARBOX_NEW_SITE_URL}${route}?${searchParams}`;
}

export const adjustSchedulesToMobiscrollObj = (schedules) => {
    schedules = schedules.map(event => {
        event.start = moment(event.date_time.date, 'YYYY-MM-DD HH:mm')
        event.end = moment(event.end_date_time.date, 'YYYY-MM-DD HH:mm')
        event.color = event.box_categories?.category_color ?? Colors.newDesign.white
        event.title = event.box_categories?.name ?? event.place ?? t('blocked-time-event')
        return event;
    })
    return schedules
}

export const adjustAvailabilityToMobiscrollObj = (availabilities) => {
    let addOns = [];
    availabilities = availabilities.filter(event => {
        event = adjustSingleAvailabilityToMobiscrollObj(event)
        event = handleAvailabilityEventLogic(event);
        if(event?.overrideAddOns) {
            addOns.push(...event.overrideAddOns)
        }
        return event
    })
    availabilities.push(...addOns);
    return availabilities
}

export const adjustSingleAvailabilityToMobiscrollObj = (event) => {
    event.start = moment(`${event.date} ${event.start_time}`, 'YYYY-MM-DD HH:mm:ss')
    event.end = moment(`${event.date} ${event.end_time}`, 'YYYY-MM-DD HH:mm:ss')
    event.color = Colors.newDesign.white
    event.title = `${event.first_name} ${event.last_name}`
    if(event.repeat) {
        event.recurring = {
            repeat: 'weekly',
            from: event.date
        }
    }
    return event;
}

export function isWeb() {
    return Platform.OS.toLowerCase() === 'web';
}

export const generateEmbedHTML = (link) => {
    let elem = document.createElement("iframe");
    elem.setAttribute("width", "100%");
    elem.setAttribute("height", "600");
    elem.setAttribute("src", link);
    return elem.outerHTML
}

export const camelize = (str) => {
    if (!str) return '';
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) return "";
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
}

export const snakeToCamel = str =>
    str.toLowerCase().replace(/([-_][a-z])/g, group =>
    group
        .toUpperCase()
        .replace('-', '')
        .replace('_', '')
);

export const shareLinkOnMobile = async (title, text, url) => {
    const shareData = {
        title: title,
        text: text,
        url: url
    }
    try {
        await navigator.share(shareData);
    } catch(err) {
        console.error("could not open navigation.share");
        await navigator.clipboard.writeText(url);
    }
}

export const copyToClipboard = async (text) => {
    try {
        await navigator.clipboard.writeText(text);
        return true;
    }
    catch(err) {
        return false;
    }

}

export const getQueryObject = (search) => {
    return JSON.parse('{"' + decodeURI(search.replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}');
}

export const getRedirectLinkToStripeConnect = (user, box) => {
    var state = {
        boxId: box.id,
        locationId: box.locations_box[0].id,
        stripe: true
    }
    return "https://connect.stripe.com/oauth/v2/authorize?scope=read_write&response_type=code&client_id=" + process.env.REACT_APP_STRIPE_CLIENT_ID +
        "&stripe_landing=register&state=" + JSON.stringify(state) +
        "&stripe_user[email]=" + user.email +
        "&stripe_user[url]=" +
        "&stripe_user[phone_number]=" + user.phone +
        "&stripe_user[business_name]=" + box.name +
        "&stripe_user[first_name]=" + user.first_name +
        "&stripe_user[last_name]=" + user.last_name +
        "&stripe_user[street_address]=" +
        "&stripe_user[city]=" + user.city +
        "&stripe_user[state]=" +
        "&stripe_user[zip]=" +
        "&stripe_user[physical_product]=false" +
        "&stripe_user[country]=" + user.country +
        "&stripe_user[currency]=" + box.locations_box[0].currency +
        "&redirect_uri=" + window.location.origin +  "/from-stripe-redirect";
}

export const checkIfAgentIsAllowed = () => {
	const webviews = ["FBAN", "FBAV"];
	const userAgentToLower = window.navigator?.userAgent?.toUpperCase();
	let isAllowed = true;
	webviews.forEach(webviewType =>
		isAllowed = isAllowed && !userAgentToLower.includes(webviewType)
	);
	return isAllowed;
}

export const getBorderColorByType = (type) => {
    switch(type) {
        case STAFF_TYPE:
            return Colors.newDesign.staffMemberBorder;
        case AGGREGATOR_TYPE:
            return Colors.newDesign.freefitBorder;
        case LEAD_TYPE:
            return Colors.newDesign.leadBorder;
        default:
            return;
    }
}

export const sortBetweenDatesDESC = (datesArray) => {
    return datesArray.sort(function (left, right) {
        return moment.utc(left.timeStamp).diff(moment.utc(right.timeStamp))
    });
}

export const fitnessBoxTypes = [
    BoxTypes.CROSSFIT, BoxTypes.GROUP_TRAINING, BoxTypes.YOGA, BoxTypes.PILATES, BoxTypes.OUTDOOR,
    BoxTypes.MARTIAL_ARTS, BoxTypes.CYCLING, BoxTypes.DANCE_STUDIO, BoxTypes.RUN, BoxTypes.OTHER,
    BoxTypes.CLIMBING, BoxTypes.PERSONAL_TRAINING, BoxTypes.GYM, BoxTypes.CORPORATE_TRAINING,
    BoxTypes.COMMUNITY_CENTER_POOL, BoxTypes.TENNIS, BoxTypes.SURFING, BoxTypes.BARRE, BoxTypes.NINJA,
    BoxTypes.POLE_FITNESS, BoxTypes.SWIMMING , BoxTypes.BATTING_CAGES,
    BoxTypes.SOCCER_FOOTBALL, BoxTypes.FOOTVOLLEY, BoxTypes.VOLLEYBALL];

export const beautyBoxTypes = [
    BoxTypes.NAIL_SALON, BoxTypes.WAXING_SALON, BoxTypes.HAIR_SALON, BoxTypes.BARBER_SHOPS,
    BoxTypes.BEAUTY_SALON, BoxTypes.TANNING_STUDIO, BoxTypes.BEAUTICIAN,];

export const wellnessBoxTypes = [BoxTypes.CHIROPRACTORS, BoxTypes.SPA_MASSAGE,  BoxTypes.POSTPARTUM];

export const wellnessClinicBoxTypes = [BoxTypes.NUTRITIONISTS, BoxTypes.PHYSIOTHERAPISTS, BoxTypes.PSYCHOLOGISTS, BoxTypes.CLINICS, BoxTypes.HYDROTHERAPY];

export const educationBoxTypes = [BoxTypes.MUSICIANS, BoxTypes.TUTORS, BoxTypes.LANGUAGE_TEACHERS,  BoxTypes.PROFESSIONAL_COURSES,
    BoxTypes.ONLINE_COURSES, BoxTypes.CHILD_TUTORS, BoxTypes.EDUCATION ];

export const otherBoxTypes = [
    BoxTypes.BANDS, BoxTypes.HANDYMAN, BoxTypes.DOG_WALKER, BoxTypes.SHOOTING_RANGE,
    BoxTypes.OTHER, BoxTypes.CERAMIC, BoxTypes.BABYSITTING, BoxTypes.HOUSEKEEPER,
    BoxTypes.TATTOO_STUDIO, BoxTypes.AFTER_SCHOOL_ACTIVITIES, BoxTypes.ARTS,  BoxTypes.CHILD_CARE,
    BoxTypes.DOGS_CARE, BoxTypes.DOGS_TRAINING, BoxTypes.ICE_SKATING, BoxTypes.PLAYGROUND, BoxTypes.SKATING,  BoxTypes.YOUTH_SPORTS, BoxTypes.ARMY_PREP];


export const aspectRatio916 = 0.5625;

export const serviceTypeIcons = {'class': 'fa-users', 'appointment': 'fa-user-clock', 'workshop': 'fa-calendar-range'}

export const firstDaysOfTrial = 3;

export const dbDateFormat = 'YYYY-MM-DD'
export const dbTimeFormat = 'HH:mm:ss'
export const dbDateTimeFormat = 'YYYY-MM-DD HH:mm:ss'

export const messageAudienceByType = {
    [PUSH]: ['ActiveMembersMessage', 'StaffMembersMessage', 'PrivateMessage'],
    [SMS]: ['ActiveMembersMessage', 'NonActiveMembersMessage', 'StaffMembersMessage', 'OpenLeadsMessage', 'LostLeadsMessage', 'PrivateMessage'],
    [EMAIL]: ['ActiveMembersMessage', 'NonActiveMembersMessage', 'StaffMembersMessage', 'OpenLeadsMessage', 'LostLeadsMessage', 'PrivateMessage'],
    [WHATSAPP]: ['ActiveMembersMessage', 'StaffMembersMessage', 'PrivateMessage'],
}

export const isBoxIL = () => {
    const box = store.getState().box.box
    return box?.locations_box[0].country_code === 'IL';
}

export const emojisReqex = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g


export async function handleShareClick(text, url) {
    const shareData = {
        title: "Arbox",
        text: text,
        url: url
    }
    try {
        await navigator.share(shareData);
    } catch(err) {
        console.error("could not open navigation.share");
        await navigator.clipboard.writeText(url);
    }
}

export const isScheduleException = (error) => {
    return error && (error?.message.includes('Schedule Exception') || error?.message === 'Schedule Is Full')
}

export const isLimitExceededException = (error) => {
    return error && (error?.message.includes('limit exceeded'))
}

export const getMessageToUserFromError = (error, joinString = '\n') => {
    if(error && error?.messageToUser) {
        if(Array.isArray(error.messageToUser)) {
            return error.messageToUser.map(errorMsg => `\u2022 ${errorMsg.message}`).join(joinString)
        } else return error.messageToUser
    }
    return ''
}

export const getBoxDateFormat = () => {
    const box = store.getState().box.box;
    return box?.locations_box[0]?.date_format;
}

export const getBoxTimeFormat = () => {
    const box = store.getState().box.box;
    return box?.locations_box[0]?.time_format;
}

export const getLocale = () => {
    const lang = store.getState().userSettings.lang;
    return lang?.language === 'Hebrew' ? 'he' : 'en';
}

export const sortDays = (a, b) => {
    if(isBoxIL()) {
        return a - b
    } else {
        if(a === 0) return 1
        if(b === 0) return -1
        return a - b
    }
}

export const getHourAndMinuteDiff = (startTime, endTime) => {
    const start = moment(startTime, dbTimeFormat)
    const end = moment(endTime, dbTimeFormat)
    const duration = moment.duration(end.diff(start));
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) % 60;
    return {hours, minutes, fullLength: duration.asMinutes()}
}

export const isBoxFitnessType = () => {
    const box = store.getState().box.box;
    return fitnessBoxTypes.includes(box.box_type.type)
}

export const isBoxInTrial = () => {
    const box = store.getState().box.box;
    return box.packages?.name.toLowerCase().includes('trial')
}

export const getSessionTypeName = (categoryTypeId) => {
    const categoryTypes = store.getState().schedule.categoryTypes;
    const category = categoryTypes?.find(cat => cat.id === categoryTypeId)
    return category?.name;
}

export const getOrderedEventTypes = (has, allowBothCourses = false) => {
    const categoryTypes = store.getState().schedule.categoryTypes;
    const types = categoryTypes?.reduce((acc, type) => {
        const hideHugimOrWorkshop = (type.name === 'hugim' && !has('hugim')) || (type.name === 'workshop' && has('hugim'))
        if(!allowBothCourses && hideHugimOrWorkshop) return acc
        return {...acc, [type.name]: type}
    },{})
    let coursesType
    if(has('hugim')) {
        coursesType = allowBothCourses ? [types.hugim, types.workshop] : [types.hugim]
    } else {
        coursesType = [types.workshop]
    }
    return [isBoxFitnessType() ? [types.appointment, types.class, ...coursesType] : [types.class, types.appointment, ...coursesType], [dummyBlockedEventType]]
}

export const dummyBlockedEventType = {id: null, name: 'blocked'};

export const isBoxFree = () => {
    const box = store.getState().box.box;
    return box.packages?.name.toLowerCase().includes('free')
}

export const isStringWithoutEmoji = (str) => {
    if (typeof str !== 'string') return true;
    const regex_emoji = /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/u;
    return !regex_emoji.test(str);
}

export const arrayToObject = (objOrArray) => {
    if (!objOrArray)
        return {}
    if (Array.isArray(objOrArray)) {
        return objOrArray.reduce((a, b) => ({...(a || {}), ...(b || {})}), {})
    }
    return objOrArray
}

export const isActive = item => {
    return item?.active === 1;
}

export const noCoaches = staff => {
    return staff?.roles.filter(role => ![Roles.COACH, Roles.BASICMANAGER].includes(role.role))?.length
}

export const SortMembersQuickViewManage = (members) => {
    if(!members || members.length <= 0) return;

    const sortedMembers = members.sort((a, b) => {

        if (a.type === LEAD_TYPE && b.type !== LEAD_TYPE) {
            return -1;
        }
        if (a.type !== LEAD_TYPE && b.type === LEAD_TYPE) {
            return 1;
        }

        if (a.regular_client && !b.regular_client) {
            return -1;
        }
        if (!a.regular_client && b.regular_client) {
            return 1;
        }

        return 0;
    })

    return sortedMembers;
}

export const translateExitNumberToCode = (number, duration = null) => {
    const isTibboOrManaged = checkIsTibboOrManaged();
    switch (number) {
        case 1:
            if (isTibboOrManaged){
                console.log('IM IN TIBBO CASE GATE 1')
                return {
                    open: new TextEncoder().encode(`Opn01,${duration ?? 1}`),
                    close: new TextEncoder().encode('Cls01')
                }
            }
            console.log('NO TIBBO - regular 1')
            return {
                open: new Uint8Array(['65'].map(hex => parseInt(hex, 16))),
                close: new Uint8Array(['6F'].map(hex => parseInt(hex, 16)))
            }
        // return ['65','6F'];
        case 2:
            if (isTibboOrManaged) {
                console.log('IM IN TIBBO CASE GATE 2')
                return {
                    open: new TextEncoder().encode(`Opn02,${duration ?? 1}`),
                    close: new TextEncoder().encode('Cls02')
                }
            }
            console.log('NO TIBBO - regular 2')
            return {
                open: new Uint8Array(['66'].map(hex => parseInt(hex, 16))),
                close: new Uint8Array(['70'].map(hex => parseInt(hex, 16)))
            }
        // return ['66','70'];
        case 3: {
            return {
                open: new TextEncoder().encode(`Opn03,${duration ?? 1}`),
                close: new TextEncoder().encode('Cls03')
            }
        }
        case 4: {
            return {
                open: new TextEncoder().encode(`Opn04,${duration ?? 1}`),
                close: new TextEncoder().encode('Cls04')
            }
        }
    }
}

export const checkIsTibboOrManaged = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const type = searchParams.get('type');
    console.log('type in search PARAMS', type);
    return type === 'tibbo' || type === 'managed';
}


export const checkGate = async(selectedPort) => {
    console.log(selectedPort,'checkGate');
    const optionalPorts = store.getState().accessControl.optionalPorts;
    const settings = store.getState().accessControl.settings;
    const gatesObj = store.getState().accessControl.gatesObj;
    const gatePort = optionalPorts.find((port) => port.label === selectedPort.gate);
    const isTibboOrManaged = checkIsTibboOrManaged();
    console.log('THATS WHAT I FOUND', gatePort);
    console.log('checkGate gatesObj', gatesObj);
    if (gatePort && !gatePort.readable) {
        await gatePort.open({ baudRate: 9600 });
    }
    const duration = isTibboOrManaged ? Math.ceil(settings.gateOpenDuration) : (parseFloat(settings.gateOpenDuration) * 1000)
    let gateWriter;
    if(!gatePort.writable.locked){
        console.log('not locked yet so working?')
         gateWriter = gatePort.writable.getWriter();
    }else{
        console.log('trying writer anyway');
         gateWriter = gatesObj[selectedPort.gate];
    }
    const gateWritable = translateExitNumberToCode(selectedPort.gateNumber || 1, isTibboOrManaged ? duration : null);
    if(gateWriter){
        await gateWriter.write(gateWritable.open.buffer);
        if(!isTibboOrManaged) {
            await new Promise((resolve) => setTimeout(resolve, duration)); // Delay for gate open
            await gateWriter.write(gateWritable.close.buffer);
            // gateWriter.releaseLock();

        }
    }

}

export const getPortNaming = (port) =>{
    console.log('NO TIBBO!! note')
    const usbProductId = port.getInfo().usbProductId;
    const usbVendorId = port.getInfo().usbVendorId;
    // 24597-1027 -- Reader
    if(usbProductId === 24597 || usbVendorId === 1027){
        return `Reader-${usbProductId}-${usbVendorId}`;
    }
    // 65518-1240 -- Gate
    if(usbProductId === 65518 || usbVendorId === 1240){
        return `Gate-${usbProductId}-${usbVendorId}`;
    }
    return `Serial-Port-Gate`;
}

export const getPortNamingTibbo = (port, createUUID = false, index = false,settings = null) => {
    console.log('port info', port.getInfo(),'We in tibbo');
    const searchParams = new URLSearchParams(window.location.search);
    const flip = searchParams.get('flip');
    console.log('flipper', flip);
    const uuid = Math.floor(Math.random() * 10000).toString().padStart(4, '0');
    if (createUUID) {
        return `Com-Connection-${uuid}`
    } else {
        console.log('flip:',flip)
        if (index === 0){
            return flip ? settings?.gates[0]?.gate : settings?.gates[0]?.reader;
        }else{
            return flip ? settings?.gates[0]?.reader : settings?.gates[0]?.gate;
        }
    }
}

export const daysLeftHelper = (box) => {
    const currDate =  moment();
    const EndDate =  moment(box.trial_mode?.end_date);
    return EndDate.diff(currDate, 'days') + 1; //Adding 1 because differance is not considering first day as part of the 14 days
}

export async function portWHOMI(port, search = false) {


    try {
        console.log('IN portWHOMI',port);
        if(!port.readable){
            await port.open({baudRate:9600});
        }
        const writer = port.writable.getWriter();
        const reader = port.readable.getReader();
        // Writing to the port
        await writer.write(new TextEncoder().encode("RDI"));
        // writer.releaseLock();
        // Reading from the port
        let keepReading = true;
        let response = '';
        console.log('keep reading starts')
        while (keepReading) {
            const { value, done } = await reader.read();
            if (done) {
                console.log('DONE CALLED',done, value);
                keepReading = false;
            } else {
                // Assuming the response is text
                const text = new TextDecoder().decode(value);
                response += text;
                console.log('Response while',response);
                console.log('Text while',text);
                if(response.toLowerCase().includes('-r')){
                    keepReading = false;
                }
            }
        }
        console.log('REACH RESPONSE END', response);
        reader.releaseLock();
        writer.releaseLock();
        return response.trim();
    } catch (error) {
        console.error('Error during serial port operation', error);
    } finally {
        console.log('REACHED FINALLY')
    }
}




export const hasBirthdayToday = (bookedUser) => {
    const monthDayFormat = 'MM-DD';
    return moment().format(monthDayFormat) === moment(bookedUser?.birthday).format(monthDayFormat)
}

export const userInHisFirstMonth = (date) => {
    return moment().diff(moment(date), 'month') < 1
}

export const openUpgrade360Link = () => {
    const user = store.getState().user.user;
    const box = store.getState().box.box;
    const extraQs = `profile.studio_name=${box.name || ''}&profile.contact_name=${user.full_name || ''}&profile.contact_phone_number=${user.phone || ''}&email=${user.email || ''}&profile.studio_number=${box.phone || ''}&profile.company_name=Arbox`;
    fetchApi('integrations/createUuid', 'get', null, false, true).then(uuid => {
        window.open(`${process.env.REACT_APP_UPGRADE_360_REG_URL}?api_key=${uuid}&${extraQs}`, '_blank')
    });
}

export const numberWithCommas = (x) => {
    return x?.toString?.().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatDate = (date) => {
    if (/[0-9]{4}-[0-9]{2}-[0-9]{2}$/y.test(date)) {
        return moment(date).format(getBoxDateFormat());
    }
    if (/[0-9]{4}-[0-9]{2}$/y.test(date)) {
        return moment(date).format('MM/YYYY');
    }
    return date;
}

export const getDateFormatByType = (type, boxDateFormat) => {
    switch (type) {
        case 'week':
          return 'wo/gggg';

        case 'month':
          return boxDateFormat.replace('DD/', '');

        case 'quarter':
          return '[Q]Q/YYYY';

        case 'year':
          return 'YYYY';

        default:
          return boxDateFormat;
      }
}

export const handlePhoneBlur = async (values, setUserPhoneExist) => {
    if (values.phone) {
        const res = await fetchApi('usersBoxes/checkIfPhoneExists', 'POST', { phone: values.phone }, false, true)
        if (res?.user_fk) {
            setUserPhoneExist(res)
        }
    }
}

export const handlePersonalIdBlur = async (personal_id, setUserPersonalIdExist, setFieldValue) => {
    let res
    try {
        if(personal_id) {
            setFieldValue('personalIdValidated', false)
            res = await fetchApi('usersBoxes/checkIfValueExists', 'POST', { personal_id }, false, true)
        } if (res?.user_fk) {
            setUserPersonalIdExist(res)
            setFieldValue('personalIdValidated', false)
        } else {
            setFieldValue('personalIdValidated', true)
        }

    } catch (e) {

    }

}

export const handleEmailBlur = async (email, setUserEmailExist, setFieldValue) => {
    let res
    if(email) {
        setFieldValue('emailValidated', false)
        res = await fetchApi('usersBoxes/checkIfValueExists', 'POST', { email }, false, true)
    } if (res?.user_fk) {
        setUserEmailExist(res)
        setFieldValue('emailValidated', false)
    } else {
        setFieldValue('emailValidated', true)
    }
}

export const showIntecom = () => {
    const alignment = i18next?.language === 'he' ? 'left' : 'right';
    window.Intercom('boot', {
        ...window.intercomSettings,
        alignment: alignment,
    });
    window.Intercom('show');
}

/**
 * 
 * @param {number} locationId 
 * @param {string} setting 
 * @returns {boolean} if location has this checkin setting
 */
export const checkinSetting = (locationId, setting) => {
    const box = store.getState().box.box;
    return !!box?.locations_box?.find(location => location.id === locationId)?.disable_pages_app?.find(locationSetting => locationSetting.section_name === setting);
}


export const RoutingFinance = [
    'invoicesAndReceipts',
    'achTransactionsReports',
    'recurringPayments',
    'finance',
    'revenue',
    'expenses',
    'incomes'
];

export const RoutingCalendar = [
    'availability',
    'schedule',
    'spaces',
    'calendarOld',
    'calendar'
];

export const NoSidebarRouting = [
    '/external/marketplace',
    '/my-app',
    '/my-links-schedule',
    '/my-links-shop',
];

export const OtherRouting = [
    '/user-profile',
    '/tasks?action=autoTaskSetting',
    '/settings?tab=2',
    '/settings?tab=3'
]

export const penaltiesDefaultMessage = {
    block_app_cancel: {
        en: `Hey {{#first_name#}},\nit has been decided to {{#penalties_type#}}\nThe penalty will begin on {{#start_date#}}, and end on {{#end_date#}}, due to {{#penalties_frequency#}} absences.`,
        he: `היי {{#first_name#}},\nהוחלט על {{#penalties_type#}} מ-{{#start_date#}} עד - {{#end_date#}} עקב {{#penalties_frequency#}} היעדרויות.`
    },
    block_app_no_cancel: {
        en: `Hey {{#first_name#}},\nit has been decided to {{#penalties_type#}}\nThe penalty will begin on {{#start_date#}}, and end on {{#end_date#}}, due to {{#penalties_frequency#}} absences.`,
        he: `היי {{#first_name#}},\nהוחלט על {{#penalties_type#}} מ-{{#start_date#}} עד - {{#end_date#}} עקב {{#penalties_frequency#}} היעדרויות.`
    },
    reduction: {
        en: 'Hey {{#first_name#}},\nit has been decided to {{#penalties_type#}} due to {{#penalties_frequency#}} absences.\nOld end date {{#start_date#}}, New end date {{#end_date#}}',
        he: 'היי {{#first_name#}},\nהוחלט על {{#penalties_type#}} עקב {{#penalties_frequency#}} היעדרויות.\nתאריך סיום ישן {{#start_date#}} ,תאריך סיום חדש {{#end_date#}}'
    }
};

export const getPenaltiesTags = (type) => [
    { id: 'first_name', label: t('firstName') },
    { id: 'last_name', label: t('lastName') },
    { id: 'penalties_type', label: t('penalties-type') },
    ...(type === 'reduction' ? [{ id: 'start_date', label: t('old-end-date') },
    { id: 'end_date', label: t('new-end-date') }] :
    [{ id: 'start_date', label: t('start-date') },
    { id: 'end_date', label: t('end-date') }]),
    { id: 'penalties_frequency', label: t('penalties-frequency') },
    { id: 'classes_penalties', label: t('classes-penalties') },
    { id: 'club_name', label: t('clubName') },
];

export const getMessageTags = () => [
    { id: 'first_name', label:'firstName' },
    { id: 'last_name', label:'lastName' },
    { id: 'date', label:'saleDate', dateType: true },
    { id: 'created_at', label:'createdAt', dateType: true },
    { id: 'age', label:'age' },
    { id: 'membership_type_name', label:'item' },
    { id: 'date', label:'scheduleDate', dateType: true },
    { id: 'time', label:'time', timeType: true },
    { id: 'coach', label:'coaches' },
    { id: 'category', label:'class' },
    { id: 'space', label:'spaceColumn' },
    { id: 'transaction_date', label:'transactionDate', dateType: true },
    { id: 'membership_type_type', label:'item' },
    { id: 'amount', label: 'amount', numType: true },
    { id: 'payments_number', label:'numberOfPayment' },
    { id: 'name', label:'item' },
    { id: 'debt', label:'debt' },
    { id: 'membership_type', label:'membership' },
    { id: 'membership_type', label:'membershipColumn' },
    { id: 'paid', label:'debtOrBalance' },
    { id: 'start', label:'startDateMembership', dateType: true },
    { id: 'membership_type', label:'membershipType' },
    { id: 'end', label:'endDateMembership', dateType: true },
    { id: 'membership_type', label:'session-pack' },
    { id: 'sessions_left', label:'sessionsLeftNum' },
    { id: 'sessions_left', label:'sessionsLeft' },
    { id: 'futureBooking', label:'futureClasses' },
    { id: 'coach', label: 'coach' },
    { id: 'registered_summary', label:'bookings' },
    { id: 'checked_in_summary', label:'checkedIn' },
    { id: 'late_cancellation_summary', label:'lateCancellationReport' },
    { id: 'date', label:'entranceDate', dateType: true },
    { id: 'hour_of_enter', label:'enteringTime', timeType: true },
    { id: 'sessions_left', label:'sessionsLeft' },
    { id: 'time', label:'scheduleTime' , timeType: true},
    { id: 'category', label:'class' },
    { id: 'lost_reason', label:'leadLostReasons' },
    { id: 'source', label:'source' },
    { id: 'end', label:'expiredDate', dateType: true },
    { id: 'day', label:'day', translate: true },
    { id: 'price', label:'originalPrice' },
    { id: 'name', label:'workshopType' },
    { id: 'end_date', label:'endDate', dateType: true },
    { id: 'lastSeen', label:'lastSeen', dateType: true },
    { id: 'cancellationHours', label:'cancelledHours' },
    { id: 'deleted_at', label:'cancelTime', dateType: true },
    { id: 'created_at', label:'renewDate', dateType: true },
    { id: 'paid', label:'paid' },
    {id: 'remarks', label: t("reason"), translate: true },
    {id: 'convert_link', label: t("link-to-convert") },
]

export const targetAudienceTasks = {
    SPECIFIC_USER: 'specific_user',
    GENERAL: 'general',
    ACTIVE_CLIENTS: 'active_clients',
    INACTIVE_CLIENTS: 'inactive_clients',
    LEADS_IN_PROGRESS: 'leads_in_progress',
    LOST_LEADS: 'lost_leads',
    PERSONAL: 'personal'
};

export const targetAudienceTasksEdit = {
    SPECIFIC_USER: 'specific_user',
    GENERAL: 'general',
    PERSONAL: 'personal'
};

export const specialSignup = ['max', 'tailor-brands'];

export const sortString = (text) => {
    if (/[A-Za-z]/.test(text)) {
        return `A_${text}`;
    } else if (/[\u0590-\u05FF]/.test(text)) {
        return `B_${text}`;
    } else if (!isNaN(parseFloat(text))) {
        return `C_${text}`;
    } else {
        return `D_${text}`;
    }
}
// TODO might get back to it at some point
// export const getFilteredMembership = (memberships, sessionType, planType, itemType, serviceType, workshopType, active, future) => {
//     // if(!memberships || !memberships.membership_types) return [];
//
//     // let result = [...memberships];
//
//     const membershipTypes = [MembershipTypes.SESSION, MembershipTypes.PLAN, MembershipTypes.ITEM, MembershipTypes.SERVICE, MembershipTypes.WORKSHOP];
//
//     return memberships?.filter((membership) => (
//         (!sessionType || membership.membership_types.type === MembershipTypes.SESSION) &&
//         (!workshopType || membership.membership_types.type === MembershipTypes.WORKSHOP)
//     ));
//
// }