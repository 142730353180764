import {
    adjustSingleAvailabilityToMobiscrollObj,
    copyToClipboard,
    dbDateFormat,
    dbTimeFormat,
    generateSiteLink,
    isMobile,
    shareLinkOnMobile,
} from "./Helpers";
import moment from "moment";
import {t} from "./i18n";
import {fetchApi} from "./HTTP";
import {
    CREATE_EVENT_MODAL,
    SCHEDULE_SETTINGS_MODAL,
    BOOKING_SERVICES_DISPLAY_MODAL,
    CREATE_EVENT_TYPE_MODAL, EVENT_MANAGEMENT_MODAL, openModal, closeModal
} from "../redux/modalManagerSlice";
import {BoxCategoryTypes, Statuses} from "../Configs/DatabaseConsts";
import {
    APPOINTMENT, CLASS, HORIZONTAL, HUGIM,
    SCHEDULE_TYPE,
    SINGLE_SESSION_EDIT_TYPE, VERTICAL, WORKSHOP
} from "./ConstHelper";
import {toggleReFetchFlag} from "../redux/ScheduleSlice";
import {cleanStepper} from "../redux/stepperSlice";
import {updateLocations} from "../redux/boxSlice";
import {store} from "../redux/store";
import UpdateParticipants from "../components/Structure/Schedule/Popups/MassAction/ActionOptions/UpdateParticipants";
import {GlobalConsts} from "../styles/GlobalConsts";

export const handleAvailabilityEventLogic = (event) => {
    if(event.repeat) {
        if(event.children) {
            event.children.map(child => {
                event.recurringException = event.recurringException ? [...event.recurringException, child.date] : [child.date]
                if(!child.deleted_at) {
                    // in this case, we don't display the parent & create a separate event occasion for the child
                    return overrideParentAvailability(event, child)
                }
            })
        }
        return event;
    } else {
        return event.deleted_at ? null : event;
    }
}

const overrideParentAvailability = (parent, child) => {
    child.first_name = parent.first_name
    child.last_name = parent.last_name
    const overrideAddOns = {
        ...adjustSingleAvailabilityToMobiscrollObj(child),
        availability_limitations: child.availability_limitations.length > 0 ? child.availability_limitations : parent.availability_limitations
    }
    parent.overrideAddOns = parent.overrideAddOns ? [...parent.overrideAddOns, overrideAddOns] : [overrideAddOns]
    return parent;
}

export const getNumOfActiveFilters = (filterConfig) => {
    const isInitiated = Object.values(filterConfig).some(filter => filter.options?.length > 0)
    if(isInitiated) {
        return Object.values(filterConfig).reduce((acc, filterType) => {
            return acc + (filterType.selected.length > 0 ? 1 : 0)
        }, 0)
    } else {
        return -1
    }
}

export const clearAllFilters = (filterConfig) => {
    let newFilters = Object.assign({},filterConfig);
    Object.keys(newFilters).forEach(key => newFilters[key].selected = []);
    return newFilters;
}

export const generateSeriesName = (days, startDate, endDate, dateFormat, timeFormat, showShortenName, t, categoryName) => {
    const startDateNew = moment(startDate,dbDateFormat).format(dateFormat === 'DD/MM/YYYY' ? 'DD/MM' : 'MM/DD')
    const endDateNew = endDate ? moment(endDate,dbDateFormat).format(dateFormat === 'DD/MM/YYYY' ? 'DD/MM' : 'MM/DD') : t('unlimited')
    let timeDict = {}
    let seriesName = ''

    if(showShortenName) return `${startDateNew}-${endDateNew} ${categoryName}`

    days?.map((day) => {
        const startTime = moment(day.start_time, dbTimeFormat)
        const endTime = moment(day.end_time, dbTimeFormat)
        let hourRangeText = ''
        if(startTime.minutes()) {
            hourRangeText = hourRangeText.concat(`${startTime.format(timeFormat)}-`)
        } else {
            hourRangeText = hourRangeText.concat(`${startTime.format(timeFormat === 'HH:mm' ? 'H' : 'h')}-`)
        }
        if(endTime.minutes()) {
            hourRangeText = hourRangeText.concat(`${endTime.format(timeFormat)}`)
        } else {
            hourRangeText = hourRangeText.concat(`${endTime.format(timeFormat === 'HH:mm' ? 'H' : 'hA')}`)
        }
        timeDict[hourRangeText] = timeDict[hourRangeText] ? [...timeDict[hourRangeText], day.day] : [day.day]
    });

    seriesName = Object.keys(timeDict).reduce((acc, hours, i) => {
        const days = timeDict[hours].reduce((acc, day) => [...acc, `${t(`day-${day+1}`)[0]}${t(`day-${day+1}`)[1]}`],[]) // stupid way to get only the first 2 letters of each day name
        return `${acc}${days.join('+')} ${hours}${i !== Object.keys(timeDict).length -1 ? ', ' : ''}`
    }, '');
    seriesName = `${startDateNew}-${endDateNew}, ${seriesName}`
    return seriesName;
}

// export const reviveScheduleConfirm = (isSeries, onCancel, onConfirm) => {
//     return {
//         header: t('areYouSure'),
//         subheader: isSeries ? t('renewSeries') : t('renewSingle'),
//         onCancel: () => onCancel(),
//         onConfirm: () => onConfirm(isSeries)
//     }
// }
//
// export const reviveClass = async (isSeries, eventData) => {
//     const params = {...eventData, renewType: isSeries ? 'renewSeries' : 'renew'}
//     await fetchApi('schedule/reviveSchedule', 'post', params, false, true);
// }

export const convertOldScheduleDeepLink = (queryParams = {}, scheduleId = null) => {
    const action = queryParams.action
    let newQueryParams = { type: SCHEDULE_TYPE }

    if(queryParams.locationId) {
        newQueryParams.locationId = queryParams.locationId
    }
    if(scheduleId) {
        newQueryParams.deepLinkScheduleId = scheduleId
    } else {
        switch (action) {
            case "appointment":
            case "workshop":
            case "class":
            case "availability":
                newQueryParams.funnelName = CREATE_EVENT_MODAL
                newQueryParams.funnelSelection = action
                newQueryParams.selectionPropName = 'initSelection'
                break;
            case "settings":
                newQueryParams.funnelName = SCHEDULE_SETTINGS_MODAL
                break;
            case "settings-notification":
                newQueryParams.funnelName = SCHEDULE_SETTINGS_MODAL
                newQueryParams.funnelSelection = 'notifications'
                newQueryParams.selectionPropName = 'initOptionSelection'
                break;
            case "services":
                newQueryParams.funnelName = BOOKING_SERVICES_DISPLAY_MODAL
                break;
            case "services-class":
            case "services-appointment":
            case "services-workshop":
                const serviceType = action.replace('services-', '')
                newQueryParams.funnelName = BOOKING_SERVICES_DISPLAY_MODAL
                newQueryParams.funnelSelection = serviceType
                newQueryParams.selectionPropName = 'defaultTab'
                break;
            case "appointmentType":
            case "workshopType":
            case "classType":
                const type = action.replace('Type', '')
                newQueryParams.funnelName = CREATE_EVENT_TYPE_MODAL
                newQueryParams.funnelSelection = type
                newQueryParams.selectionPropName = 'type'
                break;
            case "scheduleView":
                break;
        }
    }
    return newQueryParams
}

export const handleDeepLinkEvent = async (dispatch, navigate, has, queryParams = {}) => {
    let props = { navigate: navigate }
    let funnelName;
    let onClose;

    if(queryParams.funnelName) {
        funnelName = queryParams.funnelName
        if(queryParams.selectionPropName) {
            props[queryParams.selectionPropName] = queryParams.funnelSelection
        }
    } else if(queryParams.deepLinkScheduleId) {
        const events = await fetchApi('schedule/getScheduleBetweenDates', 'post', {scheduleId: queryParams.deepLinkScheduleId, to: null, from: null, locations_box_id: queryParams.locationId}, false, true);
        if(events.data && events.data[0]) {
            let schedule = events.data[0]
            schedule.title = schedule.box_categories?.name ?? schedule.place ?? t('blocked-time-event')
            const type = schedule.box_categories?.category_type?.name

            if(type) {
                if (type === APPOINTMENT) {
                    funnelName = CREATE_EVENT_MODAL
                    props.initSelection = APPOINTMENT
                    props.eventDateTime = {date: schedule.date, start: schedule.time}
                    props.target = schedule
                    props.editType = SINGLE_SESSION_EDIT_TYPE
                    onClose = () => dispatch(cleanStepper())
                } else {
                    funnelName = EVENT_MANAGEMENT_MODAL
                    props.target = {
                        ...schedule,
                        eventDateTime: {date: schedule.date, start: schedule.time, end: schedule.end},
                    }
                    props.eventData = schedule
                    onClose = () => dispatch(closeModal(EVENT_MANAGEMENT_MODAL))
                }
            }
        }
    }
    if((queryParams.permission && has(queryParams.permission)) || !queryParams.permission) {
        dispatch(openModal({
            modalName: funnelName,
            props: props,
            onClose: onClose
        }))
    }
}

export const shareOrCopySiteLink = async (toast, siteLink) => {
    if (isMobile) {
        shareLinkOnMobile(t('share-link-schedule-title'), t('share-link-schedule-text'), siteLink);
    } else {
        const result = await copyToClipboard(siteLink);
        toast.show(t(`copy-link-schedule-${result ? 'success' : 'failed'}`),{
            type: result ? 'success' : 'danger',
            duration: 2000,
        })
    }
}

export const handleSpaceAvailabilities = (availabilities, date) => {
    let res = [...availabilities]
    let addOns = [];
    const dateMoment = moment(date, dbDateFormat)
    res = res.filter(event => {
        if(event.day_of_week === dateMoment.day()) {
            event = handleAvailabilityEventLogic(event);
            event.overrideAddOns?.forEach(item => { if (item.date === date) addOns.push(item)})
            if(event.recurringException?.includes(date)) return null
            return event
        } else return null
    })
    res.push(...addOns);
    res.sort((left, right) => moment(left.start_time, dbTimeFormat).diff(moment(right.start_time, dbTimeFormat)) + moment(left.end_time, dbTimeFormat).diff(moment(right.end_time, dbTimeFormat)))
    return res
}

export const updateSpaceInSelectedLocation = (newSpace, dispatch) => {
    const box = store.getState().box.box
    const newSpaceLocation = box.locations_box.find(location => location.id === newSpace.locations_box_id)
    let spaces = newSpaceLocation.spaces.reduce((acc,space) => space.id === newSpace.id ? [...acc, newSpace] : [...acc, space], [])
    if(!spaces.some(space => space.id === newSpace.id)) spaces.push(newSpace)
    const location = {...newSpaceLocation, spaces: spaces}
    const locations = box.locations_box.reduce((acc,l) => l.id === location.id ? [...acc, location] : [...acc, l], [])
    dispatch(updateLocations(locations))
}

export const viewOptionsValue = {
    day: { schedule: { type: 'day', timeCellStep: 30, startTime: '05:00', allDay: false, days: false }},
    week: { schedule: { type: 'week', timeCellStep: 30, startTime: '05:00', allDay: false }},
    month: { calendar: { labels: isMobile ? 1 : 3 }},
    agenda: { agenda: { type: 'week' }},
    weekList: { calendar: { type: 'week', labels: 'all'}},
}

export const spacesViewOptionsValue = {
    [HORIZONTAL]: { timeline: {type: 'day', startTime: '05:00', timeCellStep: 30}},
    [VERTICAL]: { schedule: {type: 'day', startTime: '05:00', timeCellStep: 30, allDay: false}},
}

export const getCalendarType = (view) => {
    Object.keys(view).forEach(key => {
        if('type' in view[key]) return view[key].type
    })
    return null
}

export const getScheduleItemAdditionalInfo = (item, type, length = null, hasRegular = false) => {
    let coach
    let series_name
    let info = []
    const regularClientsTotalCount = item.original.regular_clients + item.original.cancelled_regular_clients;
    const registeredUsers = item.original.status === Statuses.CANCELLED ? '0' : item.original.registered;
    switch (type) {
        case CLASS:
            coach = (!length || length.fullLength > 30) ? item.original.coach?.full_name : null
            const waitlist = item.original.in_waitlist > 0 ? ` (${item.original.in_waitlist})` : ''
            if(hasRegular && item.original.series_fk && regularClientsTotalCount > 0) {
                info = [
                    {name: `${registeredUsers}/${item.original.max_users}${waitlist}`},
                    {name: item.original.status === Statuses.CANCELLED ? '0' : regularClientsTotalCount, icon: 'fa-thumbtack', iconPrefix: GlobalConsts.iconPrefix.SOLID, iconSize: GlobalConsts.iconSize.XXS10},
                ]
            } else {
                info = [{name: `${registeredUsers}/${item.original.max_users}${waitlist}`}]
            }
            break;
        case APPOINTMENT:
            coach = (!length || length.fullLength > 30) ? item.original.coach?.full_name : null
            info = [{name: item.title, icon: null}]
            break;
        case WORKSHOP:
            info = [
                {name: `${registeredUsers}/${item.original.max_users}`},
                ...(item.original.regular_clients > 0 && item.original.status !== Statuses.CANCELLED ? [{name: item.original.regular_clients, icon: 'fa-thumbtack', iconPrefix: GlobalConsts.iconPrefix.SOLID, iconSize: GlobalConsts.iconSize.XXS10}] : []),

            ]
            coach = item.original.coach?.full_name
            break;
        case HUGIM:
            info = [
                {name: `${registeredUsers}/${item.original.max_users}`},
                ...(item.original.regular_clients > 0 && item.original.status !== Statuses.CANCELLED ? [{name: item.original.regular_clients, icon: 'fa-thumbtack', iconPrefix: GlobalConsts.iconPrefix.SOLID, iconSize: GlobalConsts.iconSize.XXS10}] : []),

            ]
            coach = item.original.coach?.full_name
            series_name = item.original.series.series_name
            break;
        default:
            coach = item.original.coach?.full_name
            break;
    }
    return {coach, info, series_name}
}

export const getScheduleItemTitle = (item, type) => {
    let name
    switch (type) {
        case CLASS:
        case WORKSHOP:
            name = item.title
            break;
        case APPOINTMENT:
            const users = item.original.booked_users
            name = users.length ? `${users[0]?.first_name} ${users[0]?.last_name ? users[0]?.last_name : ''}`: item.title
            break;
        default:
            name = item.title
            break;
    }
    return name
}