import {createSlice} from '@reduxjs/toolkit';

export const CREATE_USER_MODAL = 'createUserModal'
export const CREATE_EVENT_MODAL = 'createEventModal'
export const SCHEDULE_SETTINGS_MODAL = 'scheduleSettingsModal'
export const CREATE_EVENT_TYPE_MODAL = 'createEventTypeModal'
export const EVENT_MANAGEMENT_MODAL = 'eventManagementModal'
export const BOOKING_SERVICES_DISPLAY_MODAL = 'bookingServicesDisplayModal'
export const SETTINGS_VALUE_MODAL = 'settingsValueModal'
export const MANAGE_CATEGORIES_MODAL = 'manageCategoriesModal'
export const CREATE_GROUP_CATEGORY_MODAL = 'createGroupCategoryModal'
export const REGISTER_CHECK_IN_ACCESS_CONTROL_MODAL = 'registerCheckInAccessControlModal'
export const ACCESS_CONTROL_SETTINGS_MODAL = 'accessControlSettingsModal'
export const MULTIPLE_HOLDS_MODAL = 'multipleHoldsModal'
export const MULTIPLE_HOLDS_UPDATE_MODAL = 'multipleHoldsUpdateModal'
export const ADD_SHIFT_MODAL = 'addShiftModal'
export const MULTIPLE_UPDATE_MODAL = 'multipleUpdateModal'
export const MULTIPLE_SUBSCRIPTION_UPDATE_MODAL = 'multipleSubscriptionUpdateModal'
export const MARK_AS_LOST_MODAL = 'markAsLostModal'
export const SMS_PURCHASE_MODAL = 'smsPurchaseModal'
export const UPDATE_STATUS_MODAL = 'updateStatusModal'
export const GATE_SELECTION_MODAL = 'gateSelectionModal'
export const GATE_SELECTION_TCP_MODAL = 'gateSelectionTCPModal'
export const MESSAGE_DESCRIPTION_MODAL = 'messageDescriptionModal'

export const TASKS_SETTINGS_MODAL = 'tasksSettingsModal'

export const EDIT_ALL_TASKS_MODAL = 'editAllTasksModal'

const initValues = {
    isOpen: false,
    props: null,
    processRes: null,
    hide: false,
    onClose: null
}

const modalManagerSlice = createSlice({
    name: "modalManager",
    initialState: {
        createEventModal: {...initValues},
        createUserModal: {...initValues},
        scheduleSettingsModal: {...initValues},
        createEventTypeModal: {...initValues},
        eventManagementModal: {...initValues},
        bookingServicesDisplayModal: {...initValues},
        settingsValueModal: {...initValues},
        manageCategoriesModal: {...initValues},
        createGroupCategoryModal: {...initValues},
        registerCheckInAccessControlModal: {...initValues},
        accessControlSettingsModal: {...initValues},
        multipleHoldsModal: {...initValues},
        multipleHoldsUpdateModal: {...initValues},
        addShiftModal: {...initValues},
        multipleUpdateModal: {...initValues},
        multipleSubscriptionUpdateModal: {...initValues},
        markAsLostModal: {...initValues},
        updateStatusModal: {...initValues},
        smsPurchaseModal: {...initValues},
        messageDescriptionModal: {...initValues},
        gateSelectionModal: {...initValues},
        gateSelectionTCPModal: {...initValues},
        tasksSettingsModal: {...initValues},
        editAllTasksModal: {...initValues}
    },
    reducers: {
        openModal: (state, data) => {
            const modalName = data.payload.modalName
            if (modalName) {
                state[modalName].isOpen = true
                state[modalName].props = data.payload.props
                state[modalName].processRes = null
                state[modalName].onClose = data.payload.onClose ?? null
            }
        },
        closeModal: (state, data) => {
            const modalName = data.payload.modalName
            if (modalName) {
                state[modalName].isOpen = false
                state[modalName].props = null
            }
        },
        updateModalRes: (state, data) => {
            const modalName = data.payload.modalName
            if (modalName) {
                state[modalName].processRes = data.payload.res
            }
        },
        hideModal: (state, data) => {
            const modalName = data.payload.modalName
            if (modalName) {
                state[modalName].hide = data.payload.hide
            }
        }
    },
});

export const {openModal, closeModal, updateModalRes, hideModal} = modalManagerSlice.actions;

export default modalManagerSlice.reducer;